@import '../../../../styles/variables.scss';

.container {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 20px;
  }
  .card {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    h2 {
      margin: 0px 0px 30px 0px;
      text-align: center;
    }
    .info-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media only screen and (min-width: $md-screen) {
        flex-direction: row;
        justify-content: space-between;
      }
      .info-col {
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          width: calc(50% - 10px);
        }
      }
    }
  }
}