@import '../../../../styles/variables.scss';

.container {
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .input {
    display: flex;
    justify-content: center;
    width: 90%;
  }
  .answers-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    max-height: 70vh;
    min-height: 400px; 
    width: 100%;
    padding: 5% 0px;
    .answer {
      width: 90%;
      text-align: left;
      padding: 0px 10px;
      border-bottom: 1px solid rgba($dark , 0.2);
      p {
        font-size: 14px;
        font-family: "Ubuntu-Regular";
      }
      &:hover {
        background-color: rgba($dark , 0.1)
      }
    }
  }
}

