@import '../../styles/variables.scss';

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .period {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 0px;
    input {
      width: calc(50% - 6px);
      border: none;
      font-family: "Ubuntu-Regular";
      text-align: center;
      box-shadow: 0px 10px 28px 0px #d7d8d9;
      background-color: $white;
      padding: 8px 5px;
      border-radius: 3px;
      margin-right: 10px;
      width: 120px;
      &:focus {
        outline: 1px solid $primary;
      }
    }
  }
  .row {
    @include transition;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:not(.label) {
      padding: 10px 0px;
      padding-left: 5px;
      .col {
        &:nth-child(3) {
          font-weight: bold;
        }
      }
    }
    .col {
      padding: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(60% / 3);
      &:nth-child(3) {
        width: 40%;
      }
    }
    .toggle {
      position: absolute;
      right: 0px;
    }
  }
  .list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0px;
    overflow-y: auto;
    height: calc(100vh - 200px);
    flex-direction: column;
    width: 100%;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
  }
  .change {
    width: 100%;
    display: flex;
    flex-direction: column;
    .panel {
      border-bottom: 1px solid rgba($dark, 0.2);
      @include transition;
      overflow: hidden;
      p {
        margin: 0px;
      }
      .field {
        .label {
          font-weight: bold;
        }
      }
    }
  }
}