@import '../../../../styles/variables.scss';

/* Customize the label (the container) */ 
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    pointer-events: none;
  }
  p {
    margin: 0px;
  }
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  @include transition;
  position: absolute;
  border-radius: 3px;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $dark-lighter;
  background-color: transparent;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .checkmark {
  background-color: $primary;
  border: 1px solid $primary;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}