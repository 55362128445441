@import '../../styles/variables.scss';

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .list {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    overflow-y: auto;
    height: calc(100vh - 90px);
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
  }
}