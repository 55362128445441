@import '../../../../styles/variables.scss';

.container {
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin-top: 20px;
  }
  .list {
    width: 100%;
    .card {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin: 20px 0px;
      background-color: rgba($dark , 0.2 );
      h4 {
        margin: 0px;
      }
      button {
        @include transition;
        position: absolute;
        display: flex;
        align-items: center;
        right: 20px;
        margin-top: 0px;
        padding: 0px;
        font-family: "Ubuntu-Regular";
        svg {
          margin-left: 5px;
        }
        &:hover {
          color: $danger;
        }
      }
      input {
        padding: 10px;
        border: none;
        outline: none;
        font-family: "Ubuntu-Regular";
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }
}