@import '../../../styles/variables.scss';

.container {
  position: relative;
  ul {
    padding: 0px;
    list-style: none;
    li {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      &:hover {
        button {
          opacity: 1;
        } 
      }
      input {
        width: calc(50% - 6px);
        border: none;
        font-family: "Ubuntu-Regular";
        text-align: center;
        box-shadow: 0px 10px 28px 0px #d7d8d9;
        background-color: $white;
        padding: 8px 5px;
        border-radius: 3px;
        &:focus {
          outline: 1px solid $primary;
        }
      }
      button {
        position: absolute;
        right: -26px;
        top: 7px;
        font-size: 18px;
        color: rgba($dark, 0.5);
        opacity: 0;
      }
    }
  }
  .add {
    box-shadow: 0px 10px 28px 0px #dfe0e1;
    font-family: "Ubuntu-Regular";
    background-color: $white;
    width: 100%;
    padding: 8px 5px;
    border-radius: 3px;
    color: $primary;
  }
}