@import '../../../styles/variables.scss';

.container {
  margin-top: 25px;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col {
      width: calc(100% - 50px);
      font-size: 13px;
      &:first-child {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "AnyBody-SemiBold";
        font-size: 16px;
        border-right: 1px solid rgba($dark, 0.2);
      }
    }
  }

}