@import '../../styles/variables.scss';

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 80px );
  z-index: 0;
  overflow-y: auto;
  @media only screen and (min-width: $md-screen) {
    top: 80px;
  }
  .logo {
    margin: 20px 0px;
    min-width: 180px;
  }
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 220px);
    @media only screen and (min-width: $md-screen) {
      min-height: calc(100vh - 140px);
      align-items: center;
      flex-direction: row;
    }
    .col {
      width: 100%;
      margin-bottom: 20px;
      @media only screen and (min-width: $md-screen) {
        width: calc(50% - 20px);
        margin-bottom: 20px;
      }
      .label {
        margin-bottom: 0px;
      }
      .stars {
        margin-bottom: 20px;
      }
      &.illu {
        display: none;
        @media only screen and (min-width: $md-screen) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      h2 {
        font-family: "Anybody-Bold";
        font-size: 44px;
        margin-bottom: 0px;
      }
      h3 {
        font-family: "Anybody-Bold";
        font-size: 22px;
        color: $primary;
        margin-bottom: 0px;
      }
      .author {
        width: 100%;
        border-top: 1px solid rgba($dark , 0.2 );
        border-bottom: 1px solid rgba($dark , 0.2 );
        .author-row {
          width: 100%;
          @media only screen and (min-width: $md-screen) {
            display: flex;
          }
          p {
            svg {
              transform: translateY(3px);
              margin-right: 6px;
            }
            min-width: 156px;
            padding-right: 20px;
            &:not(:first-child) {
              padding-left: 20px;
            }
            &:not(:last-child) {
              border-right: 1px solid rgba($dark , 0.2 );
            }
          }
        }
      }
      .intro {
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 40px;
        line-height: normal;
      }
      .add-contact {
        margin: 20px 0px;
      }
      .info-row {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media only screen and (min-width: $md-screen) {
          flex-direction: row;
          justify-content: space-between;
        }
        .info-col {
          width: 100%;
          @media only screen and (min-width: $md-screen) {
            width: calc(50% - 10px);
          }
        }
      }
      .btn-container {
        display: flex;
        justify-content: space-between;
        &.start {
          justify-content: flex-start;
          .btn {
            margin-right: 20px;
          }
        }
      }
      .btn {
        p {
          margin: 0px;
          text-transform: uppercase;
          font-family: "Anybody-Bold";
          line-height: normal;
        }
        &.disabled {
          background-color: #CCCFD5;
          border: 1px solid #CCCFD5;
          pointer-events: none;
        }
      }
      p {
        line-height: 28px;
      }
    }
  }
  footer {
    a {
      color: rgba($dark , 0.5 );
      @include transition;
      &:hover {
        color: $primary;
      }
    }
  }
}