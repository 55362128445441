@import '../../../../styles/variables.scss';

.container {
  width: 100%;
  .btn {
    display: block;
    max-width: 200px;
    margin: 5px 0px;
    cursor: pointer;
    text-transform: none;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    font-size: 14px;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 3px;
    svg {
      margin-right: 10px;
      fill: $primary;
    }
  }
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .error-message {
    margin: 0px;
    color: $error;
    min-height: 20px;
    padding-top: 3px;
    font-size: 12px;
  }
  .loader {
    display: flex;
    svg {
      animation:spin 2s linear infinite;
    }
  }
  @keyframes spin {
    100% { transform:rotate(360deg)} 
  }
}