@import '../../../../styles/variables.scss';

.container {
  width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 70vh;
  overflow: hidden;
  header {
    width: 100%;
    height: 100px;
    background-color: $primary;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0px;
    .logo {
      display: flex;
      align-items: center;
      color: $white;
      img {
        margin-right: 10px;
      }
    }
    .close {
      position: absolute;
      right: 0px;
      padding: 10px;
      color: $white;
      cursor: pointer;
    }
  }
  .content {
    position: absolute;
    top: 100px;
    padding: 10px 40px;
    min-height: calc(70vh - 100px);
    width: 100%;
    .info {
      width: 100%;
      border-bottom: 1px solid rgba($dark , 0.2 );
      display: flex;
      p {
        min-width: 156px;
        padding-right: 20px;
        &:not(:first-child) {
          padding-left: 20px;
        }
        &:not(:last-child) {
          border-right: 1px solid rgba($dark , 0.2 );
        }
      }
    }
    .text {
      border-bottom: 1px solid rgba($dark , 0.2 );
      margin-bottom: 30px;
      padding-bottom: 20px;
      .list {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        .tag {
          background-color: rgba($dark , 0.2);
          color: $dark;
          padding: 5px 5px 5px 10px;
          border-radius: $radius;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 14px;
          font-family: "Ubuntu-Regular";
        }
      }
    }
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      .success {
        font-size: 13px;
        font-family: "Anybody-Bold";
        text-transform: uppercase;
      }
      .back {
        font-size: 14px;
        color: $primary;
        margin-top: 10px;
        font-family: "Ubuntu-Regular";
        text-decoration: underline;
      }
    } 
  }
}