@import '../../../styles/variables.scss';

.container {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 210px;
  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .label {
      width: 120px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-right: 10px;
      p {
        font-size: 11px;
        max-width: 100%;
        word-wrap: break-word;
        background-color: #e6e6e6;
        padding: 5px 8px;
        margin: 0px;
        border-radius: 3px;
      }
    }
  }
  .bar-container {
    padding: 10px 0px;
    width: calc(100% - 150px);
    display: flex;
    align-items: center;
    border-left: 1px solid rgba($dark, 0.2);
    .bar {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-right: 5px;
    }
    p {
      margin: 0px;
      font-family: "AnyBody-SemiBold";
      font-size: 16px;
    }
  }
}