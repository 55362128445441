@import '../../../../styles/variables.scss';

.container {
  width: 450px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  h2 {
    text-transform: uppercase;
    margin: 0px;
  }
}