@import '../../../styles/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    animation: spin 1s linear infinite;
  }  
}

@keyframes spin {
  100% { transform: rotate(360deg); 
    transform:rotate(360deg);
  }
}