@import '../../../styles/variables.scss';

.container {
  overflow-y: auto;
  .content {
    padding: 40px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    aside {
      width: calc(25% - 10px);
      .date {
        margin-top: 10px;
      }
      .back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: "Anybody-Bold";
        color: $primary;
        text-decoration: none;
        transform: translateX(-8px)
      }
      .checkbox-container {
        border-top: 1px solid rgba($dark , 0.2 );
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        label {
          margin: 10px 0px;
        }
        .input-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.disabled {
            pointer-events: none;
          }
          input {
            width: 100%;
            outline: none;
            border: none;
            background-color: $white;
            border-radius: $radius;
            font-family: "Ubuntu-Regular";
            min-height: 32px;
            padding: 6px;
            box-shadow: 2px 5px 12px #EBECED;
          }
          button {
            color: rgba($dark, 0.3);
            &:hover {
              color: $success;
            }
          }
        }
      }
      .contact {
        position: relative;
        margin: 20px 0px;
        padding-bottom: 20px;
        border-top: 1px solid rgba($dark , 0.2 );
        p {
          margin: 6px 0px;
          &.name {
            margin: 22px 0px 6px 0px;
          }
        }
        svg {
          transform: translateY(3px);
          margin-right: 5px;
        }
        button {
          position: absolute;
          font-family: "Ubuntu-Regular";
          top: 20px;
          font-size: 14px;
          text-decoration: underline;
          right: 0px;
          color: rgba($dark , 0.5 );
          &.change-author {
            position: relative;
          }
          &:hover {
            color: $dark;
          }
        }
      }
      .delegate {
        display: flex ;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($dark , 0.2 );
        p {
          margin: 0px;
        }
      }
      .delete {
        color: $danger;
        display: flex;
        align-items: center;
        margin: 10px 0px;
        padding: 0px 5px;
        font-family: "Ubuntu-Regular";
        font-size: 16px;
        border-radius: $radius;
        svg {
          margin-right: 4px;
        }
        p {
          margin: 6px;
        }
        &:hover {
          background-color: $danger;
          color: $white;
        }
      }
    }
    main {
      position: relative;
      width: calc(75% - 10px);
      padding: 0px 20px;
      .btn-oral-container {
        position: absolute;
        right:0px;
        top: -12px;
        button {
          font-family: "Ubuntu-Regular";
          background-color: $white;
          padding: 8px 18px;
          margin-right: 10px;
          border-radius: $radius;
          box-shadow: 0px 5px 16px rgb(212, 213, 213);
          color: rgba($dark , 0.5 );
          &:hover {
            transform: scale(1.1)
          }
          &.is-active {
            background-color: $dark;
            color: $white;
          }
        }
      }
      .edit {
        width: 100%;
        padding: 20px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .btn {
          color: rgba($dark , 0.2 );
          transform: translateY(9px);
          &:hover {
            color: rgba($primary , 1 );
          }
        }
        .title {
          margin: 0px;
          max-width: 70%;
        }
      }
      .info {
        width: 100%;
        border-top: 1px solid rgba($dark , 0.2 );
        display: flex;
        flex-wrap: wrap;
        p {
          min-width: 156px;
          padding-right: 20px;
          &:not(:first-child) {
            padding-left: 20px;
          }
          &:not(:last-child) {
            border-right: 1px solid rgba($dark , 0.2 );
          }
        }
      }
      .question {
        margin-bottom: 10px;
        display: flex;
        .label {
          min-width: 100px;
        }
      }
      .proofs {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        border-bottom: 1px solid rgba($dark , 0.2 );
        margin-bottom: 10px;
        padding-bottom: 20px;
        .proof {
          background-color: rgba(25, 34, 67, 0.2);
          font-family: "Ubuntu-Regular";
          border-radius: 3px;
          display: flex;
          padding: 5px;
          margin-bottom: 10px;
        }
        button {
          margin-left: 0px;
          font-family: "Ubuntu-Regular";
          display: flex;
          align-items: center;
          justify-content: center;

        }
      }
      .answer {
        border-top: 1px solid rgba($dark , 0.2 );
        margin: 20px 0px;
        padding-top: 20px;
        p {
          margin-top: 0px;
        }
        textarea {
          background-color: rgba(#EBECED, 0.5 );
          box-shadow: none;
          min-height: 200px;
        }
        .btn-answer-container {
          margin-top: 20px;
          position: relative;
          display: flex;
          justify-content: space-between;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .btn-type {
              margin-left: 0px;
              margin-top: 10px;
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .success {
              margin-left: 0px;
              margin-top: 10px;
            }
          }
          @media only screen and (min-width: 1260px) {
            .left {
              flex-direction: row;
              align-items: center;
              .btn-type {
                margin-left: 20px;
                margin-top: 0px;
              }
            }
            .right {
              flex-direction: row;
              align-items: center;
              .success {
                margin-left: 20px;
                margin-top: 0px;
              }
            }
          }
          .btn-type {
            margin-left: 20px;
          }
          .tooltip {
            position: absolute;
            margin: 0px;
            right: 0px;
            top: -25px;
            font-size: 13px;
            color: $danger;
            opacity: 0;
            @include transition;
            &.is-active {
              opacity: 1
            }
          }
          .success {
            font-size: 13px;
            margin-left: 20px;
            font-family: "Anybody-Bold";
            text-transform: uppercase;
          }
        }
      }

      .hidden-text {
        display: none;
      }
      
      .files {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .file {
          transition: all 0.2s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: rgba(25, 34, 67, 0.2);
          color: #192243;
          padding: 5px 5px 5px 10px;
          border-radius: 3px;
          margin-right: 10px;
          margin-bottom: 10px;
          font-size: 14px;
          font-family: "Ubuntu-Regular";
          .link {
            cursor: pointer;
            font-family: "Ubuntu-Regular";
            &:hover {
              text-decoration: underline;
            }
          }
          .delete {
            padding: 0px;
            margin-left: 5px;
            &:hover {
              color: $danger;
            }
            svg {
              transform: translateY(1px);
            }
          }
        }
      }
    
    }
  }
}