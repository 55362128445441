@import './variables.scss';

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  color: $dark;
  font-family: "Ubuntu-Regular" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
}

* {
  box-sizing: border-box;
}

//MAIN-CONTAINERS

.app-container {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
}

.page-container {
  @include page-container ;
  &.full-page {
    top: 0px;
    height: 100vh;
  }
  .content {
    @include content ;
  }
}

//MAIN_CLASSES

.uppercase {
  text-transform: uppercase;
}

.semi-bold {
  font-family: "AnyBody-SemiBold";
}

.bold {
  font-weight: bold;
}

.regular {
  font-family: "Anybody-Regular";
}

button {
  @include btn;
}

.m-md {
  margin: $margin-md
} 

.text-center {
  text-align: center;
}

.text-error {
  color: $error;
}

.text-success {
  color: $success;
}

.text-grey {
  color: rgba($dark , 0.5 );
}

.text-primary {
  color: $primary;
}

.box {
  @include box;
  width: 100%;
}

h1 {
  font-size: 34px;
  font-family: "Anybody-Bold";
}

h2 {
  font-size: 20px;
  font-family: "Anybody-Bold";
}

.disable-pointer {
  pointer-events: none;
}
