@import '../../../styles/variables.scss';

.container {
  max-width: 400px;
  .logo {
    display: flex;
    justify-content: center;
  }
  h4 {
    text-align: center;
  }
  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}