@import '../../../styles/variables.scss';

.container {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    .count {
      display: flex;
      align-items: flex-end;
      h2 {
        margin: 0px;
        font-size: 50px;
        line-height: 42px;
      }
      p {
        margin: 0px 10px;
      }
    }
    .select {
      width: 200px;
    }
  }
  .new {
    background-color: $grey;
  }
  .pending {
    background-color: $primary;
  }
  .resolve {
    background-color: $success;
  }
  .row {
    position: relative;
    display: flex;
    justify-content: flex-start;
    @include transition;
    .bar {
      @include transition;
    }
    .rate {
      width: 100%;
      height: 66px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }
    span {
      display: block;
      font-size: 32px;
      line-height: 40px;
      font-family: "AnyBody-SemiBold";
      padding-bottom: 4px;
      margin-left: 10px;
    }
    p {
      margin: 5px 0px;
      font-size: 13px;
      position: absolute;
      top: -26px;
    }
  }
  .labels {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    .label {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 20px;
      .puce {
        width: 12px;
        height:12px;
        border-radius: 12px;
        margin-right: 6px;
        margin-top: 2px;
      }
      p {
        font-size: 14px;
        margin: 0px;
        span {
          color: rgba($dark, 0.5);
        }
        // br {
        //   display: block;
        //   @media only screen and (min-width: 1340px) {
        //     display: none;
        //   }
        // }
      }
    }
  }
}