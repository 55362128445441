@import '../../../styles/variables.scss';

.btn {
  @include transition;
  position: relative;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  font-size: 14px;
  font-family: "Ubuntu-Regular";
  overflow: hidden;
  &.radius {
    border-radius: $radius;
  }
  &.success {
    color: $white;
    text-decoration: none;
    background-color: $success;
    border-radius: 3px;
    &:hover {
      transform: scale(1.03)
    }
    &.outline {
      border: 1px solid $success;
      background-color: $white;
      color:$success;
      &:hover {
        transform: scale(1);
        background-color: rgba($success , 0.2);
      }
    }
  }
  &.danger {
    color: $white;
    text-decoration: none;
    background-color: $danger;
    border-radius: 3px;
    &:hover {
      transform: scale(1.03)
    }
    &.outline {
      border: 1px solid $danger;
      background-color: $white;
      color:$danger;
      &:hover {
        transform: scale(1);
        background-color: rgba($danger , 0.2);
      }
    }
  }
  &.primary {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    border: 1px solid $primary;
    border-radius: 3px;
    &:hover {
      transform: scale(1.03)
    }
    &.outline {
      border: 1px solid $primary;
      background-color: $white;
      color:$primary;
      &:hover {
        transform: scale(1);
        background-color: rgba($primary , 0.2);
      }
    }
  }
  &.disabled {
    pointer-events: none;
    background-color: #D1D3D9;
    border: 1px solid #D1D3D9;
    &.isLoading {
      pointer-events: none;
      overflow: hidden;
      background-color: $success;
      border: 1px solid $success;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 150%;
        height: 100%;
        z-index: 0;
        background: repeating-linear-gradient(60deg, 
        transparent, 
        transparent 0.75rem, 
        rgba($white , 0.2) 0.75rem, 
        rgba($white , 0.2) 1.5rem);
        animation: load 1s infinite linear;
      }
      /* Loading button animation */
      @keyframes load {
        0% {
          transform: translateX(-1.75rem);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }
}