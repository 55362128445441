@import '../../../styles/variables.scss';

.container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 10px;
    h1 {
      margin: 0px;
    }
    .search {
      width: 250px;
      position: relative;
      .icon {
        position: absolute;
        right: 10px;
        top: 15px;
        opacity: 0.3;
      }
    }
    .buttons {
      display: flex;
      button {
        margin-right: 20px;
      }
    }
    .btn-create {
      @include transition;
      width: 250px;
      color: $primary;
      font-family: "Anybody-Bold";
      font-size: 18px;
      text-decoration: none;
      border: 2px dashed $primary;
      background-color: $white;
      text-transform: uppercase;
      border-radius: 3px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 10px 20px -4px rgba(136, 136, 136, 0.25);
      p {
        margin: 0px;
      }
      svg {
        margin-left: 10px;
      }
      &:hover {
        border: 2px solid $primary;
        background-color: rgba($primary , 0.1)
      }
    }
  }
  .panel {
    @include transition;
    overflow: hidden;
    .question {
      padding-bottom: 10px;
      border-top: 1px solid #EBECED;
      display: flex;
      .col {
        padding: 16px;
        width: 34.5%;
      }
    }
  }
  .row {
    @include transition;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .col {
      padding: 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc((100% - 555px) / 6);
      &.title {
        width: 300px;
      }
      &.author {
        width: 200px;
      }
      &.delay {
        svg {
          transform: translate(2px,3px);
        }
      }
      a {
        @include transition;
        text-decoration: none;
        color: $dark;
        &:hover {
          color: $primary;
        }
      }
      p {
        margin-left: 15px;
        &.tag {
          background-color:#EBECED;
          padding: 5px;
          font-size: 13px;
          border-radius: 3px;
        }
      }
    }
    .toggle {
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #EBECED;
      button {
        @include transition;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: $primary;   
        &:hover {
          transform: scale(1.2) rotate(180deg);
        }
      }
    }
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    height: calc(100vh - 200px);
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // -ms-overflow-style: none;  /* IE and Edge */
    // scrollbar-width: none;  /* Firefox */
  }
}