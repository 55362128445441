@import '../../../styles/variables.scss';

.nav {
  @include transition;
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  top:0px;
  left:0px;
  z-index: 100;
  height: 60px;
  font-size: 16px;
  font-family: "Anybody-SemiBold";
  transform: translateY(-80px);
  background-color: $white;
  @media only screen and (min-width: $md-screen) {
    height: 0px;
    box-shadow: $card-shadow;
  }
  &.nav-is-active {
    height: 0px;
    transform: translateY(0px);
    box-shadow: $card-shadow;
    @media only screen and (min-width: $md-screen) {
      height: 80px;
    }
  }
  .content {
    @include content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
    }
    h1 {
      margin: 0px;
      color: $dark;
      font-family: "Anybody-Bold";
      text-transform: uppercase;
    }
    .links {
      display: none;
      text-transform: uppercase;
      @media only screen and (min-width: $md-screen) {
        display: block;
      }
      a {
        @include transition;
        padding: 10px 20px;
        border-radius: $radius;
        color: rgba($dark , 0.25);
        text-decoration: none;
        &.is-active {
          color: $dark;
        }
      }
    }
    .export {
      position: fixed;
      top: -10px;
      z-index: 1000;
      right: 0px;
      @include transition;
      color: $primary;
      font-family: "Anybody-Bold";
      font-size: 18px;
      text-decoration: none;
      border: 2px solid $white;
      background-color: $white;
      text-transform: uppercase;
      border-radius: 3px;
      padding: 10px 20px;;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 10px 20px -4px rgba(136, 136, 136, 0.25);
      p {
        margin: 0px;
      }
      svg {
        margin-left: 10px;
      }
      &:hover {
        border: 2px solid $primary;
        background-color: rgba($primary , 0.1)
      }
    }
    .icons {
      position: relative;
      display: flex;
      justify-content: flex-end;
      button {
        position: relative;
      }
      .changes {
        position: absolute;
        left: -80px;
      }
      .notifs {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark;
        color: $white;
        top: 0px;
        right: 30px;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        p {
          text-align: center;
          font-size: 12px;
          margin: 0px;
          margin-top: 1px;
        }
      }
      @media only screen and (min-width: $md-screen) {
        min-width: 102px;
        a {
          margin-left: 15px;
        }
      }
    }
    .mobile-toggle {
      display: block;
      @media only screen and (min-width: $md-screen) {
        display: none;
      }
    }
    .logout {
      display: none;
      justify-content: flex-end;
      margin-left: 20px;
      @media only screen and (min-width: $md-screen) {
        display: flex;
      }
    }
  }

}

.links-mobile {
  @include transition;
  display: flex;
  z-index: 3000;
  width: 60%;
  min-height: 100vh;
  flex-direction: column;
  position: fixed;
  background-color: $white;
  box-shadow: 0px 13px 13px 0px rgba(156,167,177,0.80);
  transform: translateX(110%);
  right:0;
  top: 60px;
  &.isActive {
    transform: translateX(0);
  }
  @media only screen and (min-width: $md-screen) {
    display: none;
  }
  a, button {
    margin: 10px $margin-md;
    padding: 10px;
    border-radius: $radius;
    font-size: 14px;
    font-family: "Anybody-SemiBold";
    color: rgba($dark , 0.5);
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    &.isActive {
      color: $dark;
    }
  }
}

.logo {
  @include transition;
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0px;
  left:0px;
  z-index: 0;
  height: 80px;
  font-size: 16px;
  font-family: "Anybody-SemiBold";
  transform: translateY(-80px);
  background-color: $bg-color;
  &.logo-is-active {
    transform: translateY(0px);
  }
  .content {
    @include content;
    img {
      width: 160px;
    }
  }
}
