@import '../../../styles/variables.scss';

.container {
  position: relative;
  width: 100%;
  .row {
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: flex-start;
    .col {
      width: calc(75% / 2);
      padding: 0px 10px;
      &-right {
        width: 25%;
        &.delay {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 10px;
          .icons {
            margin-left: 0px;
            svg {
              margin: 13px 10px 10px 0px ;
            }
          }
          p {
            padding-right: 10px;
            &:first-child {
              padding-top: 3px;
              color: rgba($dark , 0.5);
            }
            svg {
              transform: translate(2px,3px);
            }
          }
        }
        .valid-icons {
          display: flex;
          svg {
            margin: 0px 10px 10px 0px ;
          }
        }
        &.validation-container {
          padding: 0px 20px;
          min-height: 180px;
          .valid-btn {
            @include transition;
            width: 100%;
            color: $success;
            font-family: "Anybody-Bold";
            font-size: 18px;
            text-decoration: none;
            border: 2px dashed $success;
            background-color: $white;
            text-transform: uppercase;
            border-radius: 3px;
            padding: 10px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 10px 20px -4px rgba(136, 136, 136, 0.25);
            &:hover {
              border: 2px solid $success;
              background-color: rgba($success , 0.1)
            }
            &.not {
              border: 2px dashed $grey;
              color: $grey;
              &:hover {
                border: 2px solid $grey;
                background-color: rgba($grey , 0.1)
              }
            }
          }
          .comment {
            display: flex;
            flex-direction: column;
            width: 100%;
            textarea {
              max-width: 100%;
              border-radius: 3px;
              background-color: rgba($dark , 0.1);
              min-height: 120px;
              font-family: "Ubuntu-Regular";
              outline: none;
              border: none;
              padding: 10px;
              margin-bottom: 10px;
              &::placeholder {
                font-style: italic;
              }
            }
            button {
              color: $primary;
              margin-bottom: 10px;
              font-family: "Ubuntu-Regular";
              text-decoration: underline;
              &.submit {
                background-color: $primary;
                color: $white;
                text-decoration: none;
                border-radius: 3px;
                padding: 6px;
              }
            }
          }
        }
      }

      .border {
        height: 30px;
        padding: 0px 10px;
        margin-top: 10px;
        border-left: 1px solid rgba($dark , 0.2);
      }
      .title {
        display: flex;
        align-items: center;
        .code {
          min-width: 120px;
          padding: 0px 10px ;
        }
        a {
          color: $dark;
          text-decoration: none;
          &:hover {
            color: $primary;
          }
        }
      }
      .text {
        padding: 0px 10px ;
        margin-bottom: 20px;
      }
    }
  }
}