@import '../../../styles/variables.scss';

.container {
  position: relative;
  button {
    margin-right: 5px;
  }
  .comment {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 30px;
    background-color: $white;
    box-shadow: 0px 10px 20px -4px rgba(136, 136, 136, 0.25);
    width: 260px;
    z-index: 100;
    padding: 8px;
    border-radius: 3px;
    opacity: 0;
    @include transition;
    &.active {
      opacity: 1;
    }
    p {
      margin: 0px;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}