@import '../../styles/variables.scss';

.container {
  top: 0px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  .filters {
    z-index: 0;
    top: 0px;
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    background-color: $white;
    box-shadow: 0px 10px 28px 0px #EBECED;
    .filter {
      width: 100%;
      margin-bottom: 15px;
      &:not(:first-child) {
        padding-top: 15px;
        border-top: 1px solid rgba($dark,0.2);
      }
      label {
        font-weight: bold;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
      }
    }
    .check {
      width: 100%;
      margin-bottom: 20px;
      p {
        margin-top: 2px;
        font-size: 14px;
      }
    }
  }
  .dashboard-container {
    width: calc(100% - 300px);
    height: 100%;
    overflow: auto;
    .dashboards {
      padding: 20px;
      display: flex;
      width: 100%;
      .dashboard {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        width: 100%;
        &.compare {
          min-width: calc((100vw - 370px) / 2 );
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
        section {
          display: flex;
          width: 100%;
          .block {
            border-radius: 3px;
            border: 1px solid rgba(255, 181, 135, 0.44);
            background: #FFF;
            padding: 20px;
            width: 100%;
            height: 100%;
            min-height: 316px;
            box-shadow: 0px 10px 28px 0px #EBECED;
            .pie {
              margin-top: 30px;
              height: 180px;
              width: 100%;
            }  
            path {
              &:focus {
                outline: none;
              }
            }
            h5 {
              margin: 0px 0px 20px 0px;
            }
          }
          &.row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .block {
              width: calc(50% - 10px);
            }
          }
        }
      }
    }
  }
}

.export {
  position: fixed;
  top: 60px;
  right: 35px;
  z-index: 200;
  @include transition;
  color: $primary;
  font-family: "Anybody-Bold";
  font-size: 16px;
  text-decoration: none;
  border: 2px solid $white;
  background-color: $white;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 10px 20px;;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px rgba(25, 34, 67, 0.20);
  p {
    margin: 0px;
  }
  svg {
    margin-left: 10px;
  }
  &:hover {
    border: 2px solid $primary;
    background-color: $white;
  }
}
