@import '../../../../styles/variables.scss';

/* Customize the label (the container) */ 
.editor {
  @include transition;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  font-family: "Ubuntu-Regular";
  min-height: 160px;
  padding: 14px;
  font-size: 14px;
  margin-top: 5px;
  border: 0px solid $light-grey;
  background: $white;
  box-shadow: 0px 10px 28px rgb(224, 224, 224);
  border-radius: 3px;
  margin-bottom: 20px;
  &:focus {
    outline: none;
    border: 1px solid $primary;
  }
}