@import '../../../styles/variables.scss';

.filters {
  @include anim-fade;
  transition: all 0.1s ease-in-out;
  position: fixed;
  background-color: $primary;
  top: 61px;
  left: 5%;
  border-radius: $radius;
  border: 1px solid $white;
  width: 48px;
  z-index: 100;
  display: flex;
  color: $white;
  font-size: 14px;
  @media only screen and (min-width: $md-screen) {
    left: 2%;
  }
  @media only screen and (min-width: $lg-screen) {
    left: 5%;
  }
  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  &.isOpen {
    width: calc((190px * 2) + 46px);
  }
  .btn-filters {
    width: 45px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .filter {
    @include transition;
    width: 0px;
    opacity: 0;
    &.isOpen {
      width: 190px;
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.border {
      border-right: 1px solid $white;
    }
    button {
      border-radius: 30px;
      margin-right: 5px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba($white, 0.2)
      }
      &.btn-radio {
        width: 100%;
        opacity: 0.5;
        &.isActive {
          opacity: 1;
        }
        &:hover {
          background-color: rgba($white, 0);
          opacity: 1;
        }
      }
    }
    p {
      padding-left: 10px;
      font-family: "Ubuntu-Regular";
      color: $white;
      margin: 0px;
    }
    input {
      font-family: "Ubuntu-Regular";
      background-color: transparent;
      outline: none;
      border: 0px;
      max-width: 150px;
      color: #FFFFFF;
      font-size: 14px;
      &::placeholder {
        color: #FFFFFF;

      }
    }
    .list {
      position: absolute;
      z-index: 3000;
      top: 40px;
      width: 190px;
      max-height: 400px;
      overflow-y: auto;
      padding: 0px 10px;
      background-color: $primary;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
      border-right: 1px solid $white;
      &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba($white, 0.5);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba($white, 0.8);
      }
      .search {
        width: 100%;
        input {
          background: rgba($white, 0.3);
          width: 100%;
          padding: 5px;
          max-width: 100%;
          border-radius: $radius;
          margin-bottom: 10px;
        }
      }
      p {
        color: $white;
      }
      .item {
        display: flex;
        cursor: pointer;
        justify-content: flex-start;
        align-items: center;
        margin: 15px 0px;
        opacity: 0.6;
        .option {
          width: 100%;
          display: flex;
          align-items: center;
          svg {
            min-width: 13px;
          }
        }
        .color-circle {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          margin-left: 10px;
          opacity: 0;
        }
        &.isActive {
          opacity: 1;
          .color-circle {
            opacity: 1;
          }
        }
      }
    }
  }
}