@import '../../../styles/variables.scss';

.container {
  @include transition;
  @include box;
  width: 350px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top:60px;
  z-index: 200;
  p {
    margin: 0px;
  }
  .list {
    padding: 20px;
    max-height: 70vh;
    overflow: auto;
    .notif {
      padding: 20px 0px;
      &:not(:last-child) {
        border-bottom: 1px solid rgba($dark, 0.2);
      }
      .info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      a {
        @include transition;
        color: $dark;
        text-decoration: none;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}